const getTokenOfLocalStorage = (name) => {
  return localStorage.getItem(name);
}
const getTokenOfSessionStorage = (name) => {
  return sessionStorage.getItem(name);
}

const createLocalStorage = (name, value) => {
  localStorage.setItem(name, JSON.stringify(value));
}

const createSessionStorage = (name, value) => {
  sessionStorage.setItem(name, JSON.stringify(value));
}

const availableFormStorage = (name) => {
  const data = getLocalStorage(name);
  if(data == null) return false;
  return true;
}

const getLocalStorage = (name) => {
  return JSON.parse(localStorage.getItem(name));
}

const getSessionStorage = (name) => {
  return JSON.parse(sessionStorage.getItem(name));
}

export {
  createLocalStorage,
  createSessionStorage,
  availableFormStorage,
  getLocalStorage,
  getSessionStorage,
  getTokenOfLocalStorage,
  getTokenOfSessionStorage
}